<template>
    <div>
        <form @submit.prevent="addDebtConfirm" :class="{ open : isFormOpen }">
            <div class="label select">
                <v-select
                    v-model="isDebtor"
                    :items="[{ text: 'Дал в долг', value: true }, { text: 'Мне дали', value: false }]"
                    :menu-props="{ offsetY: true, maxHeight: '50vh', color: 'accent' }"
                    label="Кто дал?"
                    solo
                    flat
                >
                    <template v-slot:selection="{ item }" >
                        <v-list-item-content>
                            <v-list-item-title class="accent--text">{{ item.text }}</v-list-item-title>
                        </v-list-item-content>
                    </template>
                </v-select>
            </div>
            <div class="label" title="Сколько?">
                <input v-model="amount" type="number" step="any" placeholder="2 300.99" @keypress='validateAmout($event)'>
                <v-select
                    :items="getCurrencyItems"
                    :menu-props="{ offsetY: true, maxHeight: '50vh' }"
                    label="Валюта"
                    class="select select-currency"
                    v-model="currency"
                    solo
                    flat
                    rounded
                >
                    <template v-slot:selection="{ item }" >
                        <v-list-item-content>
                            <v-list-item-title class="currency">
                                {{ $getCurrencySign(item.value) }}
                            </v-list-item-title>
                        </v-list-item-content>
                    </template>
                    <template v-slot:item="{ item }" >
                        <v-list-item-content>
                            <v-list-item-title class="currency">
                                {{ $getCurrencySign(item.value) }}
                            </v-list-item-title>
                        </v-list-item-content>
                    </template>
                </v-select>
            </div>
            <div class="label participant">
                <input v-if="isDebtor" v-model="participant" type="text" placeholder="Кому?">
                <input v-else v-model="participant" type="text" placeholder="Кто?">
            </div>
            <div class="label">
                <v-menu
                    v-model="menu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                >
                    <template v-slot:activator="{ on }">
                        <v-text-field
                            v-model="computedDateFormatted"
                            readonly
                            v-on="on"
                            class="calendar calendar--inline"
                        ></v-text-field>
                    </template>
                    <v-date-picker
                        v-model="date"
                        no-title
                        locale="ru"
                        :first-day-of-week="1"
                        @input="menu = false"
                    ></v-date-picker>
                </v-menu>
                <v-menu
                    ref="menu"
                    v-model="menu2"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    :return-value.sync="time"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                >
                    <template v-slot:activator="{ on }">
                        <v-text-field
                            :value="time"
                            readonly
                            v-on="on"
                            class="calendar calendar--inline-time"
                        ></v-text-field>
                    </template>
                    <v-time-picker
                        v-if="menu2"
                        v-model="time"
                        no-title
                        @click:minute="$refs.menu.save(time)"
                    ></v-time-picker>
                </v-menu>
            </div>
            <div class="comment label">
                <i class="fal fa-pen-alt"></i>
                <input v-model="comment" type="text" placeholder="Комментарий">
            </div>
            <div class="addBtn">
                <half-circle-spinner
                    v-if="isLoading"
                    class="loading"
                    :animation-duration="1000"
                    :size="15"
                    :color="'#FFF'"
                />
                <input class="add" type="submit" value="Добавить">
            </div>
        </form>
        <div v-if="error" class="error">
            {{ errorMsg || 'Пожалуйста заполните все обязательные поля!' }}
            <i @click="error = false" class="far fa-times"></i>
        </div>
        <div class="newOperation" @click="isFormOpen = !isFormOpen">
            <p v-if="isFormOpen"><i class="far fa-chevron-up"></i> Свернуть</p>
            <p v-else @click="prepareDates"><i class="far fa-plus"></i> Добавить долг</p>
        </div>
        <div v-if="getReturnedDebts.debts.length && isMobileMenu" class="eye-returned" @click="SHOW_RETURNED()">
            <span v-if="!getReturnedDebts.showReturned"><i class="far fa-eye"></i> Показать возвращенные</span>
            <span v-else><i class="far fa-eye-slash"></i> Скрыть возвращенные</span>
        </div>
        <transition name="dataFormat" mode="out-in">
            <spinner v-if="isMounting" />
            <h5 v-else-if="!getDebts.length">Долгов нет!</h5>
            <VueScrollbar
                v-else
                :maxHeight="contentHeight"
            >
            <div class="operations">
                <transition-group name="list-complete">
                <div v-for="debts in getDebtsByDay" :key="debts[0].date" class="list-complete-item">
                    <p>{{ getDate(debts[0].date) }}</p>
                    <ul>
                        <transition-group name="list-complete">
                        <li v-for="debt in debts" :key="debt.id"
                            class="list-complete-item"
                            :class="{ 'returned' : debt.isReturned}"
                            :title="(debt.isReturned) ? 'Возвращено - ' + getDate(debt.updatedAt) : ''"
                        >
                            <span class="delOperation">
                                <i title="Возвращено" class="far fa-check" @click="debtReturnedConfirm(debt)"></i>
                                <i title="Удалить" class="far fa-times" @click="delDebtBtn(debt.id)"></i>
                            </span>
                            <label>
                                <span>{{ debt.time.slice(0,5) }}</span>
                                <span class="nowrap" :class="(debt.isDebtor) ? 'account' : 'income'">{{ (debt.isDebtor) ? 'Я' : debt.participant }}</span>
                                <h6></h6>
                            </label>
                            <label>
                                <i title="одолжил" class="far fa-long-arrow-right"></i>
                                <span class="nowrap" :class="(debt.isDebtor) ? 'spend' : 'account'">{{ (debt.isDebtor) ? debt.participant : 'Мне' }}</span>
                                <i class="fal fa-pen-alt"></i>
                            </label>
                            <label>
                                <span class="nowrap">{{ debt.comment || '...' }}</span>
                                <span :class="(debt.isDebtor) ? 'spend' : 'income'">{{ debt.amount | amount }} {{ $getCurrencySign( debt.currency ) }}</span>
                            </label>
                        </li>
                        </transition-group>
                    </ul>
                </div>
                </transition-group>
            </div>
            </VueScrollbar>
        </transition>
    </div>
</template>

<script>
import $ from 'jquery';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { HalfCircleSpinner } from 'epic-spinners';
import VueScrollbar from 'vue-scrollbar-live';
import EventBus from '@/event-bus';

export default {
    name: 'debts',
    components: {
        HalfCircleSpinner,
        VueScrollbar
    },
    data() {
        return {
            menu: false,
            menu2: false,
            isDebtor: true,
            participant: '',
            lang: 'ru',
            date: null,
            time: null,
            amount: null,
            currency: 'UAH',
            comment: '',
            error: false,
            errorMsg: '',
            isLoading: false,
            isMounting: true,
            isFormOpen: false,
            days: [
                'Воскресенье',
                'Понедельник',
                'Вторник',
                'Среда',
                'Четверг',
                'Пятница',
                'Суббота',
            ],
            contentHeight: (document.documentElement.clientWidth < 1440) ? 'auto' : 'calc(100vh - 265px)',
        }
    },
    computed: {
        ...mapGetters([
            "getDebts",
            "getDebtsByDay",
            "currentUser",
            "getAccounts",
            "getCurrencyItems",
            "getReturnedDebts",
            "isMobileMenu"
        ]),
        getDebtorItems() {
            return [
                ...this.getAccounts.map(account => {
                    return {
                        text: account.name,
                        value: account.id + '/' + account.currency + ''
                    }
                })
            ];
        },
        computedDateFormatted() {
            if (!this.date) return null;

            const [year, month, day] = this.date.split('-');
            return `${day}.${month}.${year}`;
        }
    },
    async mounted() {
        EventBus.$on('OPEN_ADD_OPERATION_FORM', this.openAddOperationForm);
        this.currency = this.currentUser.currency;
        this.prepareDates();

        try {
            await this.checkCachedDebts();
            await this.loadDebts();
        } catch (error) {
            this.errorMsg = error;
        }
        this.isMounting = false;
    },
    beforeDestroy() {
        EventBus.$off('OPEN_ADD_OPERATION_FORM', this.openAddOperationForm);
    },
    methods: {
        ...mapActions([
            "loadDebts",
            "addDebt",
            "delDebt",
            "debtReturned",
            "checkCachedDebts"
        ]),
        ...mapMutations(["SHOW_RETURNED"]),

        prepareDates() {
            this.date = new Date(new Date() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10);
            this.time = new Date().toLocaleTimeString('en-GB').substr(0, 5);
        },
        getDate(date) {
            date = new Date(new Date(date).getTime() + 3000 * 60 * 60);

            return this.days[date.getDay()] + ', ' + this.addZero(date.getDate()) + '.' + this.addZero(date.getMonth() + 1);
        },
        addZero(date) {
            return date < 10 ? '0' + date : date;
        },
        validateAmout(evt) {
            let theEvent = evt || window.event;
            // Handle paste
            let key;
            if (theEvent.type === 'paste') {
                key = event.clipboardData.getData('text/plain');
            } else {
                // Handle key press
                key = theEvent.keyCode || theEvent.which;
                key = String.fromCharCode(key);
            }
            let regex = /[0-9]|\.|,/;
            if (!regex.test(key) ) {
                theEvent.returnValue = false;
                if (theEvent.preventDefault) theEvent.preventDefault();
            }
        },
        addDebtConfirm() {
            let title = (this.isDebtor) ?
                `Списать ${ this.amount } ${ this.$getCurrencySign(this.currency) } с: `:
                `Добавить ${ this.amount } ${ this.$getCurrencySign(this.currency) } на: `;
            this.$showConfirmDialog({
                title,
                description: this.getAccounts,
                approvedCallback: async (index) => {
                    await this.addDebtBtn(this.getAccounts[index].id);
                },
                cancelCallback: async () => {
                    await this.addDebtBtn();
                }
            });
        },
        debtReturnedConfirm(debt) {
            let title = (debt.isDebtor) ?
                `Добавить ${ debt.amount } ${ this.$getCurrencySign(debt.currency) } на:` :
                `Списать ${ debt.amount } ${ this.$getCurrencySign(debt.currency) } с:`;
            // check account index from which to suggest discharge money
            let accountIndex = 0;
            this.getAccounts.forEach((acc, i) => {
                if (acc.id === debt.accId) {
                    accountIndex = i;
                    return;
                }
            });
            this.$showConfirmDialog({
                title,
                description: this.getAccounts,
                approvedCallback: async (index) => {
                    debt.accId = this.getAccounts[index].id;
                    await this.debtReturned(debt);
                },
                cancelCallback: async () => {
                    debt.accId = null;
                    await this.debtReturned(debt);
                },
                accountIndex
            });
        },
        async addDebtBtn(accId = null) {
            this.isLoading = true;
            this.errorMsg = '';

            if (!this.participant || !this.amount) {
                this.error = true;
                this.isLoading = false;
                return false;
            }

            if (!this.amount) {
                this.error = true;
                this.isLoading = false;
                return false;
            } else {
                this.amount = parseFloat(String(this.amount).replace(',','.').replace(' ',''));
            }

            // this.date = new Date(this.date.setHours(0, 0, 0, 0) - new Date().getTimezoneOffset() * 60000 + this.time.getHours() * 3600000 + this.time.getMinutes() * 60000  + this.time.getSeconds() * 1000);

            try {
                await this.checkCachedDebts();
                await this.addDebt({
                    isDebtor: this.isDebtor,
                    accId,
                    participant: this.participant,
                    amount: this.amount || 0,
                    currency: this.currency,
                    date: this.date,
                    time: this.time + new Date().toLocaleTimeString('en-GB').substr(5, 8),
                    comment: this.comment
                });

                // clear form
                this.isDebtor = true;
                this.participant = '';
                this.date = new Date(new Date() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10);
                this.time = new Date().toLocaleTimeString('en-GB').substr(0, 5);
                this.amount = null;
                this.currency = this.currentUser.currency;
                this.comment = '';
                this.error = false;
            } catch (error) {
                this.error = true;
                this.errorMsg = error;
            }

            this.isLoading = false;
        },
        delDebtBtn(id) {
            this.$showConfirmDialog({
                title: 'Безвозвратно удалить долг?',
                description: 'Информация про долг будет полностью удалена!',
                approvedCallback: async () => {
                    try {
                        await this.delDebt(id);
                    } catch (error) {
                        this.error = true;
                        this.errorMsg = error;
                    }
                }
            });
        },
        openAddOperationForm() {
            this.isFormOpen = !this.isFormOpen;
        }
    },
    watch: {
        error: function (value) {
            if (!value) return;
            $('html, body').animate({
                scrollTop: $(document).scrollTop() + 36,
            }, 300);
        }
    }
}
</script>

<style scoped lang="scss">
    @import '@/assets/finances.scss';
    @import '@/assets/list.scss';

    form {
        .label.select {
            flex: 1 0 15%;
            max-width: 15%;
            min-width: 130px;
        }
        .label.participant input[type='text'] {
            padding: 0 10px;
            width: 80px;
            max-width: none;
            text-align: left;
        }
    }

    li.returned {
        span:not(.delOperation), label, span:not(.delOperation) i {
            opacity: 0.7;
        }
        span.delOperation i:first-child {
            display: none;
        }
        &::before {
            position: absolute;
            left: 0;
            content: '';
            height: 1px;
            background: $text-light;
            width: calc(100% - 40px);
            margin: 0 20px;
        }
    }

    .eye-returned {
        text-align: center;
        padding-right: 15px;
        font-size: 16px;
        padding-bottom: 25px;
        i {
            font-size: 14px;
            margin-right: 5px;
        }
    }

    @media screen and (max-width: 768px) {
        form {
            .label.select {
                flex: 1 0 45%;
                max-width: 45%;
            }
            .label.participant input[type='text'] {
                width: 100%;
            }
            .select-currency {
                flex: 0;
            }
        }
    }

    @media screen and (max-width: 610px) {
        form .label.select {
            flex: 1 0 100%;
            max-width: 100%;
        }
    }
</style>
<style lang="scss">
    @import '@/assets/vars.scss';

    .v-list-item__icon .v-icon.v-icon {
        font-size: 20px;
    }
    .v-list .v-list-item {
        min-height: unset;
    }
    .v-list .v-list-item__content {
        padding: 8px 0;
    }

    .select {
        &.select-currency.v-select.v-text-field input{
            display: none;
        }
        .v-select.v-text-field input {
            flex: 0 0;
        }
        .v-select__selections {
            justify-content: center;
            i {
                font-size: 20px;
            }
        }
        .v-list-item__content {
            flex: 0 1 auto;
            max-width: 100%;
        }
        .v-label {
            transform: translateX(calc(-50% - 10px));
            left: 50% !important;
        }
        .v-input__icon .v-icon {
            color: $text;
        }
    }
</style>
